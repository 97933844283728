<template>
<div>
  <v-btn :disabled="disabled" color="medium_blue" @click="fetch" text><v-icon class="mr-2">far fa-file-excel</v-icon> Exportar</v-btn>
  <v-dialog v-model="dataReady" max-width="500">
    <v-card :loading="loading" :disabled="loading">
      <v-card-title>
        <h1 class="text text-h5 titulo"><v-icon class="mr-2">far fa-file-excel</v-icon>Exportar</h1>
      </v-card-title>
      <v-card-text class="">
        <!-- <vue-json-to-csv
          :json-data="workOrders"
          :labels="labels"
          :csv-title="csvTitle"
          :separator="';'"
          @success="val => handleSuccess(val)"
          @error="val => handleError(val)"
          class="mr-2"
          >
          <v-btn color="success">
            <v-icon class="mr-2">fas fa-download</v-icon> <b>Descargar</b>
          </v-btn>
        </vue-json-to-csv> -->
        <v-btn class="my-2" :disabled="loading" block outlined color="success" @click="getExcel(detail=false)">
            Lista de Ordenes de Trabajo
        </v-btn>
        <v-btn class="my-2" :disabled="loading" block outlined color="success" @click="getExcel(detail=true)">
             Detalle con Estimados
        </v-btn>
       
       
        
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import notifications from '@/utils/notifications'
import {fetchPostCostWorkOrders,excelInvoicedWorkOrders} from '@/api/reports'
import VueJsonToCsv from 'vue-json-to-csv'
import moment from 'moment'
import numeral from 'numeral'
export default {
  name:"ExportInvoiceWorkOrders",
  props:['filter','options','disabled','search'],
  components: {VueJsonToCsv,},
  data() {
    return {
      loading: false,
      workOrders:[],
      dataReady:false,
      labels:{ 
        id:{title:'Nro'},
        created_at:{title:'Creada'},
        completed_at:{title:'Finalizada'},
        name: { title: 'Proyecto' },
        sales_note:{title:'Nota de Venta'},
        customer_name:{title:'Cliente'},
        seller_full_name:{title:'Vendedor'},
        total_sale:{title:'Venta'},
        total_invoiced:{title:'Neto Fact.'},
       },
       csvTitle:`export_work-order_invoice_report_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}`
    }
  },
  methods:{
    async fetch(){
      this.dataReady=true
      // this.loading=true
      // var created_before = ''
      // var created_after = ''
      // if(this.filter.created_after) created_after = moment(this.filter.created_after).utc().format('yyyy-MM-DD HH:mm')
      // if(this.filter.created_before) created_before=moment(this.filter.created_before).utc().add(1439,'minutes').format('yyyy-MM-DD HH:mm')
      // if(!this.filter.seller) this.filter.seller=''
      // if(!this.filter.has_invoiced) this.filter.has_invoiced=''
      // const {sortBy, sortDesc,page, itemsPerPage }=this.options
      // const query=`search=${this.search}&ordering=${sortDesc[0]?sortBy[0]:'-'+sortBy[0]}&page=1&created_after=${created_after}&created_before=${created_before}&sales_note__customer__seller=${this.filter.seller}&has_invoices=${this.filter.has_invoiced}`
      // await fetchPostCostWorkOrders(query)
      // .then((data)=>{
      //   this.workOrders = data.results
      //   this.workOrders.forEach(w=>{
      //     w.created_at=moment(w.created_at).format("DD/MM/yyyy HH:mm")
      //     w.completed_at=w.completed_at?moment(w.completed_at).format("DD/MM/yyyy HH:mm"):'',
      //     w.total_sale=numeral(w.real_price.sales_total).format('0')
      //     w.total_invoiced=numeral(w.total_invoiced_payed.invoiced).format('0')
          
      //   })
      //   this.dataReady=true
      // })
      // .catch((err)=>notifications.showError('Error Cargando Ordenes de Trabajo' + err))
      // //Calculo porcentaje de completado del producto
      this.loading=false
    },
    handleSuccess(val){
      console.log(val)
      notifications.showSuccess('Ordenes de Trabajo Exportadas')
      this.dataReady=false
    },
    handleError(val){
      console.log(val)
      notifications.showError('Error Exportando Ordenes de Trabajo')
      this.dataReady=false
    },
    async getExcel(detail){
      this.loading=true
      var created_before = ''
      var created_after = ''
      if(this.filter.created_after) created_after = moment(this.filter.created_after).utc().format('yyyy-MM-DD HH:mm')
      if(this.filter.created_before) created_before=moment(this.filter.created_before).utc().add(1439,'minutes').format('yyyy-MM-DD HH:mm')
      if(!this.filter.seller) this.filter.seller=''
      if(!this.filter.has_invoiced) this.filter.has_invoiced=''
      const {sortBy, sortDesc,page, itemsPerPage }=this.options
      const query=`detail=${detail}&search=${this.search}&ordering=${sortDesc[0]?sortBy[0]:'-'+sortBy[0]}&page=1&created_after=${created_after}&created_before=${created_before}&sales_note__customer__seller=${this.filter.seller}&has_invoices=${this.filter.has_invoiced}`
      await excelInvoicedWorkOrders(query)
      .then((response) => {
        const link = document.createElement('a');
        link.href=window.URL.createObjectURL(response);
        link.setAttribute('download', `export_work-order_invoice_report_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.loading=false
      })
      .catch((error) => {
        if(error.code=="ECONNABORTED") notifications.showError('Error Exportando Ordenes de Trabajo: Tiempo de Espera Excedido')
        else notifications.showError('Error Exportando Ordenes de Trabajo')
        this.loading=false
      });
    }
  }

}
</script>